import React from "react"
import Button from "components/library/button/baseButton"
import { FlexContainer } from "components/library/containers"
import { StyledPrimaryButton } from "./styled/styledPrimaryButton"

interface Props {
  onClick?: () => any
  icon?: string
  text: string
  isLink?: boolean
  id?: string
  className?: string
  iconToRightText?: boolean
  type?: "button" | "submit" | "reset" | undefined
  width?: string
  bgcolor?: string
  borderColor?: string
  borderColorHover?: string
  disabled?: boolean
  isVisible?: boolean
}
export const PrimaryButton: React.FC<Props> = ({
  onClick,
  icon,
  text,
  isLink,
  className,
  iconToRightText,
  width,
  bgcolor,
  borderColor,
  borderColorHover,
  disabled,
  isVisible = true,
  ...props
}) => {
  if (!isVisible) {
    return null // Do not render the button if isVisible is false
  }

  return (
    <StyledPrimaryButton className={className} width={width}>
      <Button
        bgcolor={bgcolor ?? "var(--color-ebatePrimaryButton)"}
        color="var(--color-white)"
        onClick={onClick}
        fontSize="var(--font-size-sm)"
        isLink={isLink}
        width={width ?? "100%"}
        borderColor={borderColor ?? "var(--color-ebatePrimaryButtonBorder)"}
        borderColorHover={
          borderColorHover ?? "var(--color-ebatePrimaryButtonBorderHover)"
        }
        disabled={disabled}
        data-testid={props.id}
        {...props}
      >
        <FlexContainer
          justify="center"
          align="center"
          direction={iconToRightText ? "row-reverse" : "row"}
        >
          {icon && (
            <i
              className={`${icon} content-button icon-button primary-icon-button`}
            />
          )}
          <div className="content-button"> {text}</div>
        </FlexContainer>
      </Button>
    </StyledPrimaryButton>
  )
}
