import React, { useContext } from "react"
import { PrimaryButton } from "components/library"
import { useToastContext } from "components/hooks"
import { useTranslation } from "react-i18next"
import { createNewCalculationJob } from "queries"
import { BEConfigurationContext } from "stores"
import { StyledCalculationButton } from "./styled/styledCalculationButton"

interface Props {
  onClick?: () => any
  icon?: string
  text: string
  isLink?: boolean
  id?: string
  className?: string
  iconToRightText?: boolean
  type?: "button" | "submit" | "reset" | undefined
  width?: string
  bgcolor?: string
  borderColor?: string
  borderColorHover?: string
  disabled?: boolean
  isVisible?: boolean
}
export const CalculationButton: React.FC<Props> = ({
  onClick,
  icon,
  text,
  isLink,
  className,
  iconToRightText,
  width,
  bgcolor,
  borderColor,
  borderColorHover,
  disabled,
  isVisible = false,
  ...props
}) => {
  if (!isVisible) {
    return null // Do not render the button if isVisible is false
  }

  const commonLang = "common"
  const { t } = useTranslation(["translation", commonLang])
  const { tenantConfiguration } = useContext(BEConfigurationContext)
  const isRunCalculationManually =
    tenantConfiguration.runCalculationManually === true

  const onSave = () => {
    save()
  }
  const addToast = useToastContext()

  const save = async () => {
    const response = await createNewCalculationJob(1)
    if (response?.status === 200) {
      if (!response.data.isValid) {
        showToastWarningMessage(response.data.errors?.[0]?.message)
      } else {
        showToastSuccessMessage("Calculation started")
      }
    } else {
      showToastErrorMessage(response.data.errors?.[0]?.message)
    }
  }
  const showToastWarningMessage = (message: string) => {
    const toast = {
      severity: "warn",
      summary: "Warn",
      detail: message,
      life: 5000,
      position: "top-center",
      sticky: false,
    }

    if (addToast) addToast(toast)
  }

  const showToastErrorMessage = (message: string) => {
    const toast = {
      severity: "error",
      summary: "Error",
      detail: message,
      life: 5000,
      position: "top-center",
      sticky: true,
    }
    if (addToast) addToast(toast)
  }

  const showToastSuccessMessage = (message: string) => {
    const toast = {
      severity: "success",
      summary: "Success",
      detail: message,
      position: "top-center",
      sticky: false,
    }
    if (addToast) addToast(toast)
  }
  return (
    <StyledCalculationButton className={className} width={width}>
      <PrimaryButton
        text={t(`${commonLang}:run-cal`)}
        width="150px"
        id="popup-save"
        onClick={() => onSave()}
        isVisible={isRunCalculationManually && isVisible}
      />
    </StyledCalculationButton>
  )
}
