import { ValidationResultModel } from "components/models"
import EbateAxios from "queries/ebateAxios"

const calculationUrl = "/api/calculationjob"

export const isAnyJobInProgress = async () => {
  const url = `${calculationUrl}/IsAnyJobInProgress`
  const { AxiosGet } = EbateAxios()
  return AxiosGet<Boolean>(url)
}

export const createNewCalculationJob = async (calculationJobType: number) => {
  const url = `${calculationUrl}/CreateNewCalculationJob/${calculationJobType}`
  const { AxiosGet } = EbateAxios()
  return AxiosGet<ValidationResultModel>(url)
}
