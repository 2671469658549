import React, { useState } from "react"
import {
  Dropdown,
  FlexContainer,
  IconButton,
  IconContainer,
  FilterBlock,
  Fade,
  CalculationButton,
} from "components/library"
import { StyledCommandBar } from "./styled"
import {
  CommandBarFilterType,
  ComponentFilterModal,
  QuickSearchModel,
} from "./commandBar.models"

interface Props {
  onViewSwitch?: () => void
  cardView?: boolean
  onSort?: any
  onAdd?: () => void
  sortItems?: any
  initialSort?: any
  onUpload?: () => void
  filterType?: CommandBarFilterType
  onCancel?: () => void
  componentFilterModal?: ComponentFilterModal
  componentQuickSearch?: QuickSearchModel
  isReadOnly?: boolean
  showCalButton?: boolean
}

const CommandBar: React.FunctionComponent<Props> = ({
  onViewSwitch,
  cardView,
  onSort,
  onAdd,
  sortItems,
  initialSort,
  onUpload,
  onCancel,
  componentFilterModal,
  filterType,
  componentQuickSearch,
  isReadOnly,
  showCalButton = false,
}) => {
  const [sortBy, setSortBy] = useState(initialSort)

  const icons = () => (
    <IconContainer>
      {onViewSwitch ? (
        <IconButton
          name="command-switch-view"
          id="command-switch-view"
          icon="pi pi-table"
          onClick={onViewSwitch}
        />
      ) : null}
      {onAdd && !isReadOnly ? (
        <IconButton
          name="command-add"
          id="command-add"
          icon="pi pi-plus"
          onClick={onAdd}
        />
      ) : null}
      {onUpload && !isReadOnly ? (
        <IconButton
          name="command-upload"
          icon="pi pi-cloud-upload"
          onClick={onUpload}
        />
      ) : null}
      {onCancel && !isReadOnly ? (
        <IconButton
          name="command-cancel"
          icon="pi pi-times"
          onClick={onCancel}
        />
      ) : null}
    </IconContainer>
  )

  const sort = () => (
    <>
      {sortItems && (
        <Fade collapse={cardView}>
          {cardView && (
            <Dropdown
              transparent
              options={sortItems}
              canSearch={false}
              width="250px"
              id="sortdropdown"
              name="sort"
              value={sortBy}
              border="1px solid var(--color-ebatePrimary)"
              onChange={(e) => {
                setSortBy(e.target.value)
                onSort(e.target.value)
              }}
            />
          )}
        </Fade>
      )}
    </>
  )

  return (
    <StyledCommandBar>
      <FlexContainer className="wrapper">
        <FlexContainer className="search">
          {filterType !== undefined &&
            filterType !== CommandBarFilterType.NoFilter && (
              <FilterBlock
                filterType={filterType}
                componentQuickSearch={componentQuickSearch}
                componentFilterModal={componentFilterModal}
              />
            )}
        </FlexContainer>
        <FlexContainer className="sort-icons" justify="flex-end">
          <CalculationButton text="" isVisible={showCalButton} />
          {sort()}
          {icons()}
        </FlexContainer>
      </FlexContainer>
    </StyledCommandBar>
  )
}

export default CommandBar
