import styled from "styled-components"

interface Props {
  width?: string
}
export const StyledCalculationButton = styled.div<Props>`
  width: ${({ width }) => width || "100%"};

  &:hover {
    .content-button {
      font-weight: bold;
    }
  }

  .content-button {
    font-weight: normal;
  }

  .icon-button {
    padding: 0 var(--spacing-half);
  }

  .primary-icon-button {
    font-size: var(--font-size-xs);
  }
`
