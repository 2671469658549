import { OptionApi } from "components/helpers"
import EbateAxios from "queries/ebateAxios"
import { RebateDetailsApi } from "components/pages/pricemanagement/rebates/rebateDetails/rebateDetails.models"
import { TradingGroupCompanyResponse } from "components/pages/maintanance/tradingGroup/tradingGroupDetail/tradingGroupDetail.models"
import { CompanyComboValue } from "components/pages/maintanance/company/companyList/company.models"
import { FinancialAccount } from "components/pages/pricemanagement/rebates/rebateAllocation/rebateAllocationModels"
import {
  AgreementsByCalcTypeRequest,
  ProductsOfAgreementsRequest,
  RatesOfAgreementsRequest,
  RebatesOfAgreementsRequest,
} from "components/pages/pricemanagement/exportImportRates/exportRates/exportRates.models"

const dropdownDataUrl = "/api/DropdownData"

export const getPaymentAdjustmentTypes = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetPaymentAdjustmentTypes`)
}

export const getRebatesByCompany = async (companyId: number) => {
  const { AxiosGet } = EbateAxios()
  return AxiosGet<RebateDetailsApi[]>(
    `${dropdownDataUrl}/GetRebatesByCompany/${companyId}`
  )
}

export const getActiveLinkedAccountByTradingGroup = async (
  companyId: number
) => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<TradingGroupCompanyResponse[]>(
    `${dropdownDataUrl}/GetActiveLinkedAccountByTradingGroup/${companyId}`
  )
}

export const getRebateCategories = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetRebateCategories`)
}

export const getCompanyTypes = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetCompanyTypes`)
}

export const getPeriods = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetPeriods`)
}

export const getDocumentCategories = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetDocumentCategories`)
}

export const getDocumentTags = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetDocumentTags`)
}

export const getCompaniesComboValue = async (
  companyTypeId?: number,
  accountTypeId?: number,
  search?: string
) => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<CompanyComboValue[]>(
    `${dropdownDataUrl}/GetCompaniesComboValues`,
    { companyTypeId, accountTypeId, search }
  )
}

export const getCompaniesComboValueByIds = async (Ids: number[]) => {
  const { AxiosPost } = EbateAxios()
  const url = `${dropdownDataUrl}/GetCompaniesComboValuesByIds` // Ensure `url` is defined

  return AxiosPost<number[], CompanyComboValue[]>(url, Ids)
}

export const getAccountingPeriodTypes = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetAccountingPeriodTypes`)
}

export const getClaimTypes = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetClaimTypes`)
}

export const getAgreementsOfCompany = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetAgreementsOfCompany`)
}

export const getAggregationLevels = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetAggregationLevels`)
}

export const getUnitOfMeasurements = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<any>(`${dropdownDataUrl}/GetUnitOfMeasurements`)
}

export const getAllFinancialAccounts = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<FinancialAccount[]>(
    `${dropdownDataUrl}/GetAllFinancialAccounts`
  )
}

export const getAgreementStatusCombo = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetAgreementStatusCombo`)
}

export const getReportTypes = async (reportCategoryId: number) => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetReportTypes`, {
    reportCategoryId,
  })
}

export const getAgreementsByCalculationType = async (
  request: AgreementsByCalcTypeRequest
) => {
  const url = `${dropdownDataUrl}/GetAgreementsByCalculationType`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<AgreementsByCalcTypeRequest, OptionApi[]>(url, request)
}

export const getRebatesOfAgreementsByCalculationType = async (
  request: RebatesOfAgreementsRequest
) => {
  const url = `${dropdownDataUrl}/GetRebatesOfAgreementsByCalculationType`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<RebatesOfAgreementsRequest, OptionApi[]>(url, request)
}

export const getRatesOfAgreementsByCalculationType = async (
  request: RatesOfAgreementsRequest
) => {
  const url = `${dropdownDataUrl}/GetRatesOfAgreementsByCalculationType`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<RatesOfAgreementsRequest, OptionApi[]>(url, request)
}

export const getProductsOfAgreementsByCalculationType = async (
  request: ProductsOfAgreementsRequest
) => {
  const url = `${dropdownDataUrl}/GetProductsOfAgreementsByCalculationType`

  const { AxiosPost } = EbateAxios()

  return AxiosPost<ProductsOfAgreementsRequest, OptionApi[]>(url, request)
}

export const getRequestStatuses = async () => {
  const { AxiosGet } = EbateAxios()

  return AxiosGet<OptionApi[]>(`${dropdownDataUrl}/GetRequestStatuses`)
}
